import React from "react";
import image from "../../product/sol.png"

interface HeaderProductProps {
    src: string; // Define the type of the `src` prop
    name: string; 
}

function HeaderProduct({ src,name }: HeaderProductProps ) {
    return (
        <div>
        <div className="outline outline-2 outline-orange w-full flex justify-center items-center bg-darksecondarybg rounded-lg">
            <img className="w-[300px] h-auto" src={src} alt="Product" />

        </div>
        <h1 className="text-orange pt-2 text-2xl uppercase font-bold pb-2">{name}</h1>
        </div>

    );
}

export default HeaderProduct;
