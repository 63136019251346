import React, { useState, useEffect } from "react";
import { PDFDocument, TextAlignment } from 'pdf-lib';
import QRCode from 'qrcode';
import FileSaver from 'file-saver';
import fontkit from '@pdf-lib/fontkit';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa6";

interface ProductData {
    product_id: string;
    product_name: string;
    supply: number;
    image: string;
}

interface UserData {
    idhodl: string;
    product_id: string;
}

function AdminPage() {
    const [productData, setProductData] = useState<ProductData[]>([]);
    const [userData, setUserData] = useState<UserData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [fileId, setFileId] = useState<string | null>(null);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        fetchProductData();
        fetchUserData();
    }, []);

    const fetchProductData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata`);
            const data: ProductData[] = await response.json();
            setProductData(data);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/hodldata`);
            const data: UserData[] = await response.json();
            setUserData(data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const generatePDF = async (product: ProductData) => {
        try {
            console.log('Loading PDF template...');
            const pdfDoc = await PDFDocument.load(await fetch('/pdf/labelsolana.pdf').then(res => res.arrayBuffer()));
            console.log('PDF template loaded.');
    
            const newPdfDoc = await PDFDocument.create();
            
            // Fetch user data for the current product
            const usersForProduct = userData.filter(user => user.product_id === product.product_id);
            
            for (let i = 0; i < usersForProduct.length; i++) {
                const user = usersForProduct[i];
                const [newPage] = await newPdfDoc.copyPages(pdfDoc, [0]);
                newPdfDoc.addPage(newPage);
    
                const datalink = `${process.env.REACT_APP_SERVER_ORIGIN}/productid/${user.idhodl}`;
                console.log(`Generating QR code for: ${datalink}`);
                const qrCodeDataUrl = await QRCode.toDataURL(datalink);
                console.log('QR code generated.');
    
                const qrImage = await newPdfDoc.embedPng(await fetch(qrCodeDataUrl).then(res => res.arrayBuffer()));
                const qrImageDims = qrImage.scale(0.40);
    
                const pageWidth = newPage.getWidth();
                const pageHeight = newPage.getHeight() + 10;
                const x = (pageWidth - qrImageDims.width) / 2;
                const y = (pageHeight - qrImageDims.height) / 2;
    
                newPage.drawImage(qrImage, {
                    x: x,
                    y: y,
                    width: qrImageDims.width,
                    height: qrImageDims.height,
                });
    
                const url = '/font/mokoto.ttf';
                const fontBytes = await fetch(url).then(res => res.arrayBuffer());
    
                newPdfDoc.registerFontkit(fontkit);
                const montfont = await newPdfDoc.embedFont(fontBytes);
    
                const form = newPdfDoc.getForm();
                const textField = form.createTextField(`hodl_id_${i}`);
                textField.setText(user.idhodl);
                textField.addToPage(newPage, { 
                    x: 7, 
                    y: 28,
                    borderWidth: 0,
                    height: 18,
                    width: 60,
                    font: montfont,
                });
                textField.setFontSize(18);
                textField.setAlignment(TextAlignment.Right);
    
                form.flatten();
            }
    
            const pdfBytes = await newPdfDoc.save();
            FileSaver.saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), `label_${product.product_id}.pdf`);
            console.log(`PDF saved as label_${product.product_id}.pdf`);
    
        } catch (error) {
            console.error('Error generating or saving PDF:', error);
        }
    };

    const viewDetails = (productId: string) => {
        navigate(`/admin/productdetails/${productId}`);
    }

    const Generate = () => {
        navigate(`/admin/bulkgenerator`);
    }
    return (
        <div className="py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[1200px] mx-auto">
            <div className="space-y-2 pb-10">
                <h1 className="text-orange text-2xl uppercase font-bold pb-5">Product List</h1>
                <button
                                        className=" bg-white text-black px-4 py-2 rounded"
                                        onClick={() => Generate()}
                                    >
Bulk Generator
                                    </button>
            </div>
            
            {/* Product Data Table */}
            <div className="overflow-x-auto">

                <table className="min-w-full bg-darksecondarybg text-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b border-darkbg">Product ID</th>
                            <th className="py-2 px-4 border-b border-darkbg">Product Name</th>
                            <th className="py-2 px-4 border-b border-darkbg">Supply</th>
                            <th className="py-2 px-4 border-b border-darkbg">Image</th>
                            <th className="py-2 px-4 border-b border-darkbg">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productData.map((product) => (
                            <tr key={product.product_id}>
                                <td className="py-2 px-4 border-b border-darkbg">{product.product_id}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{product.product_name}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{product.supply}</td>
                                <td className="py-2 px-4 border-b border-darkbg">
                                    <img src={`https://drive.google.com/thumbnail?id=${product.image}&sz=w400`}  alt={product.product_name} className="w-16 h-auto"/>
                                </td>
                                <td className="py-2 px-4 border-b border-darkbg">
                                    <button
                                        className="bg-orange text-black px-4 py-2 rounded"
                                        onClick={() => generatePDF(product)}
                                    >
                                        Generate PDF
                                    </button>
                                    <button
                                        className="bg-white text-black px-4 py-2 rounded"
                                        onClick={() => viewDetails(product.product_id)}
                                    >
                                        <FaEye />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            {isLoading && (
                <div className="flex justify-center items-center mt-4">
                    <div className="loader"></div>
                </div>
            )}
        </div>
    );
}

export default AdminPage;
