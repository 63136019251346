import React from "react";

function ErrorPage(){
    return(
        <div className="py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[450px] mx-auto">
            <h1 className=" text-white">Page Not Found</h1>
        </div>
    );
}

export default ErrorPage;