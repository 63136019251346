import React from "react";
import logo from '../../logo/solana.png';

function PoweredSolana(){
    return(
        <div className="flex justify-center items-center m-auto">
            <img className="h-[40px]" src={logo} />
        </div>
    );
}

export default PoweredSolana;