import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Set the background color
document.body.style.background = '#171717';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <div dangerouslySetInnerHTML={{ __html: `
      <!-- Is this our own system? Yes, It is 
      .
      ..
      ...
      #### ####                        
      #### ####  ###################       
       ### ####  #####################     
       ### ####               #########    
       ### ####                ########    
       ### ####                ########    
       ### ####                ########    
       ########                ########    
       ################################    
       ################################### 
       ########                  ######### 
       ########                   ######## 
       ### ####                   ######## 
       ### ####                   ######## 
       ### ####                   ######## 
       ### ####  ######################### 
      #### ####  ########################  
      #### ####  ######################    
      ...
      ..
      .
      Hodl Group ( M ) Sdn. Bhd.
      https://101hodl.com/ -->
    ` }} />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
