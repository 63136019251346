import React from "react";
import Logo from "../logo/logolong.png";

function Home() {
    return (
        <div className="h-screen flex items-center justify-center bg-darkbg">
            <img className="h-20" src={Logo} alt="Logo" />
        </div>
    );
}

export default Home;
