import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react"; 
import { FaFileLines } from "react-icons/fa6";
import PoweredSolana from "./components/psolana";
import HeaderProduct from "./components/headerproduct";

interface HodlData {
  id: number;
  certid: string;
  date: string;
  txid: string;
  name: string;
  idhodl: string;
  product_id?: string;
}

interface ProductData {
  product_id: string;
  image: string;
  product_name: string;
}

function RenderID() {
  const { hodlID } = useParams<{ hodlID: string }>();
  const [data, setData] = useState<HodlData | null>(null);
  const [productImage, setProductImage] = useState<string | null>(null);
  const [productName, setProductName] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch hodl data
        const res = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/hodldata`);
        const hodlData = await res.json();
        const hodlItem = hodlData.find((item: HodlData) => item.id.toString() === hodlID);
        setData(hodlItem || null);

        if (hodlItem?.product_id) {
          // Fetch product data using the product_id from hodlItem
          const productResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata`);
          const productData = await productResponse.json();
          const productItem = productData.find((product: ProductData) => product.product_id === hodlItem.product_id);

          if (productItem) {
            const imageUrl = `https://drive.google.com/thumbnail?id=${productItem.image}&sz=w400`;
            setProductImage(imageUrl);
            setProductName(productItem.product_name);
          } else {
            console.error('Product not found');
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [hodlID]);

  if (!data) {
    return <p>User not found</p>;
  }

  const txidDisplay = `${data.txid.slice(0, 6)}...${data.txid.slice(-6)}`; // Shorten and format the txid

  return (
    <div className="py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[450px] mx-auto">
      {productImage && productName && (
        <HeaderProduct src={productImage} name={productName} />
      )}
      <div className="space-y-2">
        <div className="space-y-0">
          <p className="text-orange uppercase font-bold">Name</p>
          <p className="text-white uppercase">{data.name}</p>
        </div>
        <div className="space-y-0">
          <p className="text-orange uppercase font-bold">Date Minted</p>
          <p className="text-white uppercase">{data.date}</p>
        </div>
        <div className="space-y-0">
          <p className="text-orange uppercase font-bold">Transaction ID</p>
          <div className="flex items-center space-x-2">
            <a
              className="text-white underline overflow-ellipsis text-center"
              href={`https://explorer.solana.com/tx/${data.txid}?cluster=${process.env.REACT_APP_NETWORK_EXPLORER}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ maxWidth: "200px" }} // Limit width for ellipsis
            >
              {txidDisplay}
            </a>
            <FaFileLines
              onClick={() => window.open(`https://explorer.solana.com/tx/${data.txid}?cluster=${process.env.REACT_APP_NETWORK_EXPLORER}`, '_blank')}
              className="text-white h-[24px] cursor-pointer"
            />
          </div>
        </div>
        <div className="space-y-1">
          <p className="text-orange uppercase font-bold">HODL ID</p>
          <p className="text-white uppercase text-2xl">{data.idhodl} / 101</p>
        </div>
      </div>

      <iframe
        className="rounded-lg overflow-hidden"
        src={`https://drive.google.com/file/d/${data.certid}/preview`}
        width="280"
        height="400"
        allow="autoplay"
      ></iframe>

      <div className="space-y-1">
        <p className="text-orange uppercase font-bold">QR Code</p>
        <div className="bg-white p-5 rounded-lg w-fit m-auto mt-5">
          <QRCode
            value={`${process.env.REACT_APP_ORIGIN}/mintednft/${hodlID}`}
            size={100}
            bgColor="#ffffff"
            fgColor="#000000"
          />
        </div>
      </div>

      <div className="bg-black outline outline-5 outline-orange items-center justify-center flex h-[60px] w-[60px] rounded-full fixed right-5 bottom-5">
        <FaFileLines
          onClick={() => window.open(`https://solscan.io/tx/${data.txid}?cluster=${process.env.REACT_APP_NETWORK_EXPLORER}`, '_blank')}
          className="text-white h-[60px] cursor-pointer"
        />
      </div>
      <PoweredSolana />
    </div>
  );
}

export default RenderID;
