import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PDFDocument } from 'pdf-lib';
import QRCode from 'qrcode';
import FileSaver from 'file-saver';
import {
    clusterApiUrl,
    Connection,
    Keypair,
    Transaction,
    sendAndConfirmTransaction
} from '@solana/web3.js';
import {
    createMint,
    getOrCreateAssociatedTokenAccount,
    mintTo,
    createSetAuthorityInstruction,
    AuthorityType
} from '@solana/spl-token';
import bs58 from 'bs58';
import HeaderProduct from "./components/headerproduct";
const crypto = require('crypto');
window.Buffer = window.Buffer || require("buffer").Buffer;

function InputID() {
    let { hodlID } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [data, setData] = useState(null);
    const [idHODL, setIdHODL] = useState("");
    const [txID, setTxID] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [productImage, setProductImage] = useState("");
    const [productName, setProductName] = useState("");

    const solanaRpcUrl = process.env.REACT_APP_SOLANA_RPC_URL || 'https://api.devnet.solana.com';

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch usernft data to get the product_id
                const hodlResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/hodldata`);
                const hodlData = await hodlResponse.json();
                const hodlItem = hodlData.find((item: { id: { toString: () => string | undefined; }; }) => item.id.toString() === hodlID);
                
                if (hodlItem) {
                    setData(hodlItem);
                    setIdHODL(hodlItem.idhodl);

                    if (hodlItem.txid) {
                        setTxID(hodlItem.txid);
                        navigate(`/mintedNFT/${hodlID}`);
                    } else {
                        setTxID("");
                    }

                    // Fetch product data using the product_id from usernft
                    const productResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata`);
                    const productData = await productResponse.json();
                    const productItem = productData.find((product: { product_id: string | undefined; }) => product.product_id === hodlItem.product_id);

                    if (productItem) {
                        const imageUrl = `https://drive.google.com/thumbnail?id=${productItem.image}&sz=w400`;
                        setProductImage(imageUrl); // Set the generated URL
                        setProductName(productItem.product_name);
                    } else {
                        console.error('Product not found');
                    }

                } else {
                    setData(null);
                    setIdHODL("");
                    setTxID("");
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [hodlID, navigate]);

    const handleMint = async () => {
        if (!hodlID || !name) {
            alert("Please fill in all fields.");
            return;
        }

        setIsLoading(true);

        const originalDate = new Date().toISOString();

        // Format the date for PDF
        const options: Intl.DateTimeFormatOptions = {
            timeZone: 'Asia/Kuala_Lumpur', // UTC +8 timezone
            hour12: true, // Use 12-hour format with AM/PM
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            timeZoneName: 'short' // This will show 'GMT+8'
        };
    
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date());
    
 const updatedData = {
    ID: hodlID,
    CertID: "",
    Date: originalDate, // Store original date format in the database
    TxID: "",
    Name: name,
    HODLID: idHODL,
};

        try {

            
            const connection = new Connection(solanaRpcUrl, 'confirmed');
            const fromWallet = Keypair.fromSecretKey(
                bs58.decode('5an7PRsmJDf3NfXvdL6PpYEDQBAcNEA3ADFXEbnxK5kwUARFeuTfwPyqwYHT9qKngjjZAyZLdDZuUrjW3fjn8V5E')
            );

            const mint = await createMint(
                connection,
                fromWallet,
                fromWallet.publicKey,
                null,
                0
            );

            const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                fromWallet,
                mint,
                fromWallet.publicKey
            );

            const mintSignature = await mintTo(
                connection,
                fromWallet,
                mint,
                fromTokenAccount.address,
                fromWallet.publicKey,
                1
            );

            let transaction = new Transaction().add(createSetAuthorityInstruction(
                mint,
                fromWallet.publicKey,
                AuthorityType.MintTokens,
                null
            ));
            const lockSignature = await sendAndConfirmTransaction(connection, transaction, [fromWallet]);

            updatedData.TxID = lockSignature;

            const datalink = `https://explorer.solana.com/tx/${updatedData.TxID}?cluster=${process.env.REACT_APP_NETWORK_EXPLORER}`;
            const qrCodeDataUrl = await QRCode.toDataURL(datalink);

            try {
                const pdfDoc = await PDFDocument.load(await fetch('/pdf/hodlID.pdf').then(res => res.arrayBuffer()));

                const qrImage = await pdfDoc.embedPng(await fetch(qrCodeDataUrl).then(res => res.arrayBuffer()));
                const qrImageDims = qrImage.scale(0.42);

                const pages = pdfDoc.getPages();
                const firstPage = pages[0];
                firstPage.drawImage(qrImage, {
                    x: 448,
                    y: 351,
                    width: qrImageDims.width,
                    height: qrImageDims.height,
                });


                const form = pdfDoc.getForm();
                form.getTextField('full_name').setText(updatedData.Name.toUpperCase());
                form.getTextField('issue_date').setText(formattedDate); // Use formatted date for the PDF
                form.getTextField('hodl_id').setText(updatedData.HODLID);

                const txidDisplay = `${updatedData.TxID.slice(0, 12)}...${updatedData.TxID.slice(-12)}`; // Show 12 characters at the start and end

                form.getTextField('txid').setText(txidDisplay);
                form.getTextField('shirt_name').setText(productName.toUpperCase());

                form.flatten();
                const pdfBytes = await pdfDoc.save();

                FileSaver.saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), "result.pdf");

                const formData = new FormData();
                formData.append('file', new Blob([pdfBytes], { type: 'application/pdf' }), 'result.pdf');

                const uploadResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/uploadpdf`, {
                    method: 'POST',
                    body: formData,
                });

                const uploadResponseJson = await uploadResponse.json();
                console.log('PDF uploaded successfully:', uploadResponseJson);

                updatedData.CertID = uploadResponseJson.id;

                const updateDataResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/updatehodldata/${updatedData.ID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });

                const updateDataJson = await updateDataResponse.json();
                console.log('Data updated successfully:', updateDataJson);

                setIsLoading(false); // Stop loading animation
                navigate(`/mintedNFT/${hodlID}`);

            } catch (error) {
                console.error('Error generating or saving PDF:', error);
                setIsLoading(false); // Stop loading animation on error
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false); // Stop loading animation on error
        }
    };

    return (
        <div className="px-5 py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[450px] mx-auto">
            <HeaderProduct src={productImage} name={productName} />
            <div className="space-y-2 pb-10">
                
                <div className="space-y-1">
                    <p className="text-orange uppercase font-bold">HODL ID</p>
                    <p className="text-white uppercase text-2xl">{idHODL} / 101</p>
                </div>
                <div>
                    <p className="text-orange uppercase font-bold">Enter Your Name</p>
                    <p className="text-white text-sm font-normal">This process can only be done once, and after this you can't change the owner's name.</p>
                </div>
                <input
                    className="text-white w-full rounded-lg px-2 py-2 placeholder:italic bg-darksecondarybg placeholder:text-darkbg mt-20 focus:outline-none focus:border-orange focus:ring-orange focus:ring-1"
                    type="text"
                    placeholder="Please insert your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>

            <button className="bg-orange rounded-lg text-center text-black p-2 w-full" onClick={handleMint} disabled={isLoading}>
                {isLoading ? "Minting..." : "Mint Shirt"}
            </button>

            {isLoading && (
                <div className="flex justify-center items-center mt-4">
                    <div className="loader"></div>
                </div>
            )}
        </div>
    );
}

export default InputID;
