import React from "react";
import logo from '../../logo/logolong.png';

function Navbar(){
    return(
        <div className="w-full h-[60px] bg-black z-10 block fixed ">
            <img className="h-[45px] pl-5 pt-2" src={logo} />
        </div>
    );
}

export default Navbar;