import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

interface ProductData {
    product_id: string;
    product_name: string;
    supply: number;
    image: string;
}

interface HolData {
    id: string;
    name: string;
    date: string;
    certid: string;
    txid: string;
    idhodl: string;
}

function ProductDetailsPage() {
    const { productID } = useParams<{ productID: string }>();
    const [productData, setProductData] = useState<ProductData | null>(null);
    const [holData, setHolData] = useState<HolData[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch product data
                const productResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata/${productID}`);

                if (productResponse.ok) {
                    const productArray: ProductData[] = await productResponse.json();
                    if (productArray.length > 0) {
                        const product = productArray[0]; // Access the first element

                        setProductData(product);
                    } else {
                        console.error('No product found with the given ID');
                    }
                } else {
                    console.error('Failed to fetch product data');
                }
    
                // Fetch HODL data
                const holResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/hodldata/${productID}`);
                if (holResponse.ok) {
                    const hol: HolData[] = await holResponse.json();
                    setHolData(hol);
                } else {
                    console.error('Failed to fetch HODL data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [productID]);
    

    if (isLoading) {
        return <div className="loader">Loading...</div>;
    }

    if (!productData) {
        return <div>Product not found</div>;
    }
    return (
        <div className="py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[1200px] mx-auto">
            <div className="space-y-2 pb-10">
                <h1 className="text-orange text-2xl uppercase font-bold pb-5">Product Details</h1>
            </div>
            
            {/* Product Data Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-darksecondarybg text-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b border-darkbg">Product ID</th>
                            <th className="py-2 px-4 border-b border-darkbg">Product Name</th>
                            <th className="py-2 px-4 border-b border-darkbg">Supply</th>
                            <th className="py-2 px-4 border-b border-darkbg">Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="py-2 px-4 border-b border-darkbg">{productData.product_id}</td>
                            <td className="py-2 px-4 border-b border-darkbg">{productData.product_name}</td>
                            <td className="py-2 px-4 border-b border-darkbg">{productData.supply}</td>
                            <td className="py-2 px-4 border-b border-darkbg">
                                <img src={`https://drive.google.com/thumbnail?id=${productData.image}&sz=w400`} alt={productData.product_name} className="w-16 h-auto"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* HODL Data Table */}
            <div className="overflow-x-auto mt-10">
                <table className="min-w-full bg-darksecondarybg text-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b border-darkbg">ID</th>
                            <th className="py-2 px-4 border-b border-darkbg">Name</th>
                            <th className="py-2 px-4 border-b border-darkbg">Date</th>
                            <th className="py-2 px-4 border-b border-darkbg">CerID</th>
                            <th className="py-2 px-4 border-b border-darkbg">TxID</th>
                            <th className="py-2 px-4 border-b border-darkbg">IDHODL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {holData.map((item) => (
                            <tr key={item.id}>
                                <td className="py-2 px-4 border-b border-darkbg">{item.id}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.name}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.date}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.certid}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.txid}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.idhodl}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ProductDetailsPage;
